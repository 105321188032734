.nav-container {
    display: flex;
    flex-direction: column;
    order: 2;

}

.navlinks-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;
    padding: 0;
}

.navlink-active {
    color: var(--red-300);
    font-size: var(--body-text);
}
.navlink {
    transition: all ease-in-out .3s;
    flex: 1;
    font-size: var(--body-text);
    line-height: 32px;
    font-weight: var(--font-normal);
}
.navlink:hover {
   color: var(--red-300);
}

.white-active {
    color: var(--grey-300);
  }

@media only screen and (min-width: 900px) {
    .navlinks-container{
        grid-row-start: span 1 / span 4;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: center;
        margin: 0;
        gap: 24px;
        font-size: var(--body-text);
    }

    .nav-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        order: 1;

    }
    .white-active {
        color: var(--grey-200);
      }
}