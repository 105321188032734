footer {
	width: 100%;
	height: auto;
	padding-top: 80px;
	background-color: var(--white);
}

.footer-container {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 2rem;
	align-items: start;
}

.wrapper-links {
	grid-row: 1;
	grid-template-columns: repeat(3, 1fr);
	display: grid;
	gap: 1rem;
}
.wrapper-links .footer-widget {
	display: flex;
	flex-direction: column;
}

.wrapper-links .footer-widget h3 {
	font-style: normal;
	font-weight: var(--font-medium);
	font-size: var(--body-text);
	line-height: 40px;
	color: var(--grey-300);
	width: 100%;
}

.wrapper-links .footer-widget .links {
	padding: 0;
	margin: 0;
}

.wrapper-links .footer-widget .links li {
	list-style: none;
}

.wrapper-links .footer-widget .links li a {
	font-style: normal;
	font-weight: var(--font-normal);
	font-size: var(--body-text);
	line-height: 32px;
	color: var(--grey-300);
	text-decoration: none;
}
.wrapper-links .footer-widget .links li a:hover {
	color: var(--green-500);
}
.copyright-wrapper {
	padding: 20px 0;
}

.copyright-wrapper p {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
}

.copyright-wrapper p > span {
	font-size: var(--body-caption);
}

.copyright {
	font-weight: var(--font-normal);
	font-size: var(--body-caption-2);
	color: var(--grey-200);
	line-height: 18px;
	text-align: center;
}
.footer-logo-container{
	display: flex;
	justify-content: center;
	align-items: center;
}

.footer-logo{
	width: 200px;
	height: 200px;
}

/* Laptop Styling */

@media only screen and (min-width: 480px) {
	.wrapper {
		grid-template-columns: repeat(2, 1fr);
	}

}

@media only screen and (max-width: 280px) {
	.wrapper-links {
		grid-template-columns: repeat(1, 1fr);
	}
}

/* Laptop Styling */

@media only screen and (min-width: 768px) {
	.wrapper-links {
		grid-template-columns: repeat(4, 1fr);
		grid-row: 2;
		gap: 5rem;
	}
	.footer-container {
		display: flex;
		flex-direction: column;
		gap: 10rem;
	}
}


/* Extra Large Screen/Laptop Styling */
@media only screen and (min-width: 1200px) {
	.footer-container {
		display: flex;
		flex-direction: row;
		gap: 10rem;
	}
}
